import React from "react";
import { useTheme } from "context/useAppTheme";
import ToggleSide from "components/widget/ToggleSides";
import TickTok from "assets/icons/landing/TicktokIcon";
import TwitterIcon from "assets/icons/landing/TwitterIcon";
import FacebookIcon from "assets/icons/landing/FacebookIcon";
import InstagramIcon from "assets/icons/landing/InstagramIcon";
import Applogo from 'assets/icons/Applogo.svg';
import ApplogoLight from "assets/icons/AppLogoLight.svg"

interface Props {
    moveToSections: Function,
}


const Footer: React.FC<Props> = (props: Props) => {
    const { isLight } = useTheme()
    const { moveToSections } = props

    return (
        <div className={`connect_hero_container w-full mt-24`}>

            <div className={`flex justify-center mt-0 lg:py-8 ${isLight ? 'bg-white' : 'bg-night_blue_700'}`}>
                <div className="lg:w-11/12 md:w-11/12 flex  flex-wrap items-center px-3 lg:p-0">
                    <div className="w-full flex flex-wrap">
                        <div className="xl:w-2/12 w-11/12 flex items-center my-4">
                            <img alt={'alt-text'} src={isLight ? ApplogoLight : Applogo} />
                        </div>
                        <div className={`footer_links xl:w-5/12 w-full sm:w-11/12 my-4 ${isLight ? 'text-black' : 'text-white'}`}>
                            <button onClick={() => moveToSections('hero')}>
                                Home
                            </button>
                            <button>
                                About us
                            </button>
                            <button onClick={() => moveToSections('features')}>
                                Key features
                            </button>
                            <button onClick={() => moveToSections('howitworks')}>
                                How it works
                            </button>
                            <button onClick={() => false && moveToSections('contact_us')}>
                                Contacts
                            </button>

                        </div>
                        <div className="xl:w-2/12 w-full sm:w-4/12 my-6">
                            <ToggleSide />
                        </div>
                        <div className="xl:w-3/12 w-full sm:w-8/12 flex justify-start sm:justify-end items-center my-6">
                            <span className="mx-2 cursor-pointer">
                                <a href="https://www.tiktok.com/@night.owl245?_t=8sFn5kCGbQE&_r=1" target="_blanck">
                                    <TickTok color={!isLight ? 'black' : 'white'} bgcolor={!isLight ? 'white' : 'black'} />
                                </a>
                            </span>
                            <span className="mx-2 cursor-pointer">
                                <a href="example.com"><InstagramIcon color={isLight ? 'black' : 'white'} /></a>
                            </span>
                            <span className="mx-2 cursor-pointer">
                                <a href="example.com">
                                    <TwitterIcon color={!isLight ? 'black' : 'white'} bgcolor={!isLight ? 'white' : 'black'} />
                                </a>
                            </span>
                            <span className="mx-2 cursor-pointer">
                                <a href="https://www.facebook.com/share/1AmRLCtC1R" target="_blanck">
                                    <FacebookIcon color={isLight ? 'black' : 'white'} />
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <p className={`py-5 text-sm text-center w-full foot_text ${isLight ? 'text-black' : 'text-white'}`}>All rights reserved © 2024 Night owl</p>
        </div>
    );
};

export default Footer;
